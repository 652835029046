.close-icon-active {
    display: none;
}

.user-classification-chip:hover .close-icon-inactive {
    display: none;
}

.user-classification-chip:hover .close-icon-active {
    display: inherit;
}
