.classification-input-row .bin-icon {
    visibility: hidden;
    margin: 15px;
}

.classification-input-row:hover .bin-icon {
    visibility: visible;
}

.classification-input-row {
    display: flex;
    align-items: center;
    margin: 10px 0px;
}
